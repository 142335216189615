import styled from 'styled-components';

import {lgMax} from '../../../../styles/media';

export const Dekoline01Wrapper = styled.div`
    position: absolute;
    bottom: -50px;
    width: 80%;
    left: 80%;
    ${lgMax`
        display:none;
    `};
`;

export const Dekoline02Wrapper = styled.div`
    position: absolute;
    top: -30px;
    width: 60%;
    left: 70%;
    ${lgMax`
        display:none;
    `};
`;
