import React from 'react';
import {ReactComponent as Dekoline01} from '../../../../assets/images/startseite/img-dekoline-1.svg';
import {ReactComponent as Dekoline02} from '../../../../assets/images/startseite/img-dekoline-2.svg';

import {Dekoline01Wrapper, Dekoline02Wrapper} from './styles';

export function LineDeko01() {
    return (
        <Dekoline01Wrapper>
            <Dekoline01 />
        </Dekoline01Wrapper>
    );
}

export function LineDeko02() {
    return (
        <Dekoline02Wrapper>
            <Dekoline02 />
        </Dekoline02Wrapper>
    );
}
