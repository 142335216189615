import React, {useState} from 'react';
import {useSelector} from 'react-redux';

import Layout from '../ui/layout/Layout';
import {Column, Container, Row, Section} from '../ui/layout/Grid';
import Headline from '../ui/components/Headline';
import Image from '../ui/components/Image';
import Divider from '../ui/components/Divider';
import {color} from '../styles/variables';
import Jahreszeit from '../assets/images/startseite/img-features--jahreszeit.png';
import Apotheke from '../assets/images/startseite/img-features--apotheke.png';
import Mitmachen from '../assets/images/startseite/img-features--mitmachen.png';
import Paragraph from '../ui/components/Paragraph';
import Link from '../ui/components/Link';
import lang from '../lang/pages/index.lang';
import profileLang from '../lang/pages/profil.lang';
import StartheaderHeaderImage from '../assets/images/startseite/img-hero--massgeschneidert.png';
import MultiStepPersonalization from '../ui/domains/profile/MultiStepPersonalization';
import Header from '../ui/layout/Header';
import ShopWindow from '../ui/layout/ShopWindow';
import TrainingTeaser from '../ui/domains/training/Teaser';
import {LineDeko01, LineDeko02} from '../ui/domains/homepage/LineDecoration';
import Button from '../ui/components/Button/';
import {selectIsLoggedIn} from '../model/authentication';
import VideoPlayer from '../ui/components/VideoPlayer';
import Lightbox from '../ui/components/Lightbox';
import {
    selectIsPersonalizationFinished,
    selectUserCategories,
} from '../model/profile';
import {
    selectById as selectTrainingsById,
    suggest as suggestTrainings,
} from '../model/training';
import {routes} from '../model/navigation';
import {scrollToAnchorStart} from '../utils/scroll-utils';
import ResponsiveSwitch from '../ui/layout/ResponsiveSwitch';

const Home = () => {
    const isLoggedIn = useSelector(selectIsLoggedIn);
    const userCategories = useSelector(selectUserCategories);
    const isPersonalizationFinished = useSelector(
        selectIsPersonalizationFinished
    );
    const trainingsById = useSelector(selectTrainingsById);
    const {suggestedTrainings} = suggestTrainings(
        trainingsById,
        isLoggedIn,
        userCategories
    );
    const [lightboxOpen, setLightboxOpen] = useState(false);
    return (
        <Layout>
            <Header
                img={StartheaderHeaderImage}
                alt={lang.headerAlt}
                paddingTop>
                <Headline type="h1">{lang.apothekentrainingFuerDich}</Headline>
                <Paragraph>{lang.paragraph1}</Paragraph>
                <ResponsiveSwitch>
                    {(match) => {
                        if (match.smMax) {
                            return (
                                <Row>
                                    <Column
                                        xs={12}
                                        style={{paddingBottom: '1rem'}}>
                                        <Link
                                            showAsButton
                                            href={routes.trainings}>
                                            {lang.button}
                                        </Link>
                                    </Column>
                                    <Column xs={12}>
                                        <Button
                                            secondary={true}
                                            label={lang.button2}
                                            icon="triangleRight"
                                            onClick={() =>
                                                setLightboxOpen(true)
                                            }
                                        />
                                    </Column>
                                </Row>
                            );
                        } else {
                            return (
                                <Row>
                                    <Column xs={12} style={{display: 'flex'}}>
                                        <Link
                                            showAsButton
                                            href={routes.trainings}
                                            className="ap-button">
                                            {lang.button}
                                        </Link>
                                        <Button
                                            secondary={true}
                                            label={lang.button2}
                                            icon="triangleRight"
                                            onClick={() =>
                                                setLightboxOpen(true)
                                            }
                                        />
                                    </Column>
                                </Row>
                            );
                        }
                    }}
                </ResponsiveSwitch>
            </Header>
            <Section theme="grey">
                <Container width="wide" doubleGutter>
                    <Row style={{alignItems: 'center'}}>
                        <Column md={6}>
                            <Image
                                src={Jahreszeit}
                                alt={lang.img1}
                                responsive
                            />
                            <LineDeko01 />
                        </Column>
                        <Column md={6}>
                            <Headline type="h4">
                                {lang.section1headline}
                            </Headline>
                            <Paragraph>{lang.section1paragraph}</Paragraph>
                            <Link href={routes.anspruch} icon="arrowForward">
                                {lang.section1link}
                            </Link>
                        </Column>
                    </Row>
                    <Row
                        style={{
                            alignItems: 'center',
                            flexDirection: 'row-reverse',
                        }}>
                        <Column md={6}>
                            <Image src={Apotheke} alt={lang.img2} responsive />
                        </Column>
                        <Column md={6}>
                            <Headline type="h4">
                                {lang.section2headline}
                            </Headline>
                            <Paragraph>{lang.section2paragraph}</Paragraph>
                            <Link
                                href="#"
                                icon="arrowForward"
                                onClick={(event) => {
                                    event.preventDefault();
                                    scrollToAnchorStart('schaufenster-section');
                                }}>
                                {lang.section2link}
                            </Link>
                        </Column>
                    </Row>
                    <Row style={{alignItems: 'center'}}>
                        <Column md={6}>
                            <Image src={Mitmachen} alt={lang.img3} responsive />
                            <LineDeko02 />
                        </Column>
                        <Column md={6}>
                            <Headline type="h4">
                                {lang.section3headline}
                            </Headline>
                            <Paragraph>{lang.section3paragraph}</Paragraph>
                            <Link href={routes.praemien} icon="arrowForward">
                                {lang.section3link}
                            </Link>
                        </Column>
                    </Row>
                </Container>
            </Section>
            <Section>
                <div data-scroll-anchor="schaufenster-section" />
                <Container width="wide" className="text-center">
                    <Row>
                        <Column md={8} lg={6} offset={{md: 2, lg: 3}}>
                            <Headline type="h3">
                                {lang.schaufensterHeadline}
                            </Headline>
                            <Paragraph>{lang.schaufensterParagraph}</Paragraph>
                        </Column>
                    </Row>
                </Container>
                <Container width="wide">
                    <ShopWindow teasers={suggestedTrainings}>
                        {(training) => (
                            <TrainingTeaser
                                title={training.title}
                                type={training.type.name}
                                image={training.image}
                                points={training.points}
                                duration={training.duration}
                                completed={training.completed}
                                route={training.route}
                                isReleased={training.isReleased}
                            />
                        )}
                    </ShopWindow>
                </Container>
            </Section>
            <Section theme="blue">
                {/* Do not show this headline when personalization steps 1 and 2 are done */}
                {/* because then the registration form will have its own headlines */}
                {!isPersonalizationFinished && (
                    <Container width="wide" className="text-center">
                        <Row>
                            <Column md={8} lg={6} offset={{md: 2, lg: 3}}>
                                <Headline type="h3">
                                    {profileLang.personalisierung.headline}
                                </Headline>
                                <Paragraph>
                                    {profileLang.personalisierung.paragraph}
                                </Paragraph>
                            </Column>
                        </Row>
                        <Row>
                            <Column xs={12}>
                                <Divider dashed color={color.white} />
                            </Column>
                        </Row>
                    </Container>
                )}
                <MultiStepPersonalization />
            </Section>
            <Lightbox
                maxWidth="1000px"
                isOpen={lightboxOpen}
                onClose={() => setLightboxOpen(false)}>
                <VideoPlayer inLightbox src={lang.videoUrl} />
            </Lightbox>
        </Layout>
    );
};

Home.propTypes = {};

export default Home;
